import React from 'react';
import styled from '@emotion/styled';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import chunk from 'lodash/chunk';
import PageTitle from '@zd/components/page-title';

import useBlogCategories from '@zd/hooks/useBlogCategories';
import useBlogPosts from '@zd/hooks/useBlogPosts';
import PageSEO from '@zd/components/page-seo';
import { Container } from '@zd/components/grid';
import BlogPostCard from '@zd/components/blog-post-card';
import Pagination from '@zd/components/pagination';
import QuerySelect from '@zd/components/query-select';
import Link from '@zd/components/link';

import theme from '@zd/theme';

const Ul = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;

  ${theme.medias.gteSmall} {
    grid-template-columns: repeat(1, 1fr);
  }

  ${theme.medias.gteMedium} {
    grid-template-columns: repeat(1, 1fr);
  }

  ${theme.medias.gteLarge} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Li = styled.li`
  margin-bottom: 1rem;
  &:last-of-type {
    margin-bottom: 0;
  }

  > a {
    display: block;
  }
`;

const EmptyP = styled.p``;

const BLOG_POSTS_PER_PAGE = 4;

export default props => {
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [category, setCategory] = useQueryParam('category', StringParam);

  const blogCategories = useBlogCategories();
  const blogPosts = useBlogPosts();

  const blogPostsFilteredByCategory = category
    ? blogPosts.filter(post => post.frontmatter.categories.includes(category))
    : blogPosts;

  const numberOfPages = Math.ceil(blogPostsFilteredByCategory.length / BLOG_POSTS_PER_PAGE);

  const invalidPageOnQuery = !page || page > numberOfPages || page < 1;

  if (invalidPageOnQuery && numberOfPages > 0) {
    setPage(1);
  }

  const currentPage = invalidPageOnQuery ? 1 : page;

  const blogPostsToRender =
    chunk(blogPostsFilteredByCategory, BLOG_POSTS_PER_PAGE)[currentPage - 1] || [];

  const pageTitle = category ? blogCategories[category].title : 'Blog da Clínica da Boavista';

  return (
    <>
      <PageSEO {...props} title={pageTitle} />
      <Container>
        <PageTitle title={pageTitle} />
      </Container>
      <QuerySelect
        label="Categoria:"
        selected={category}
        options={Object.values(blogCategories)}
        defaultOption={{ title: 'Todas as Categorias', slug: 'all' }}
        onChange={e => {
          const slug = e.target.value;
          setPage(1);
          if (slug === 'all') {
            setCategory(undefined);
          } else {
            setCategory(slug);
          }
        }}
      />
      <Container>
        {blogPostsToRender.length > 0 ? (
          <Ul>
            {blogPostsToRender.map(post => (
              <Li key={post.fields.slug}>
                <BlogPostCard
                  slug={post.fields.slug}
                  title={post.frontmatter.title}
                  thumbnail={post.frontmatter.thumbnail.childImageSharp.fluid}
                  categories={post.frontmatter.categories}
                  date={post.frontmatter.date}
                  excerpt={post.excerpt}
                  timeToRead={post.timeToRead}
                />
              </Li>
            ))}
          </Ul>
        ) : (
          <>
            <EmptyP>
              Nenhum artigo com a categoria de <b>{blogCategories[category].title.toLowerCase()}</b>{' '}
              encontrado.
            </EmptyP>
            <br />
            <p>
              <b>
                <Link href="#" onClick={() => setCategory(undefined)}>
                  Ver todos os artigos
                </Link>
              </b>
            </p>
          </>
        )}
        <br />
        <br />
        <Pagination
          currentPage={currentPage}
          numberOfPages={numberOfPages}
          onPreviousPageClick={() => setPage(currentPage - 1)}
          onNextPageClick={() => setPage(currentPage + 1)}
          onPageClick={pageNumber => setPage(pageNumber)}
        />
        <br />
      </Container>
    </>
  );
};
