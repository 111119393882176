export default date => {
  const splittedData = (date || 'DD/MM/YYYY').split('/');

  const curatedDay = splittedData[0].startsWith('0')
    ? splittedData[0].split('')[1]
    : splittedData[0];
  let month;
  const year = splittedData[2];

  switch (splittedData[1]) {
    case '01':
      month = 'Janeiro';
      break;
    case '02':
      month = 'Fevereiro';
      break;
    case '03':
      month = 'Março';
      break;
    case '04':
      month = 'Abril';
      break;
    case '05':
      month = 'Maio';
      break;
    case '06':
      month = 'Junho';
      break;
    case '07':
      month = 'Julho';
      break;
    case '08':
      month = 'Agosto';
      break;
    case '09':
      month = 'Setembro';
      break;
    case '10':
      month = 'Outubro';
      break;
    case '11':
      month = 'Novembro';
      break;
    case '12':
      month = 'Dezembro';
      break;
    default:
      break;
  }

  return `${curatedDay} de ${month} de ${year}`;
};
