import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { BsArrowRightShort as ArrowIcon } from 'react-icons/bs';

import LazyImage from '@zd/components/lazy-image';
import formatDate from '@zd/utils/formatDate';
import Link from '@zd/components/link';

import theme from '@zd/theme';

const StyledLink = styled(Link)`
  display: block;
  color: ${theme.colors.mainGrey};

  &:hover {
    color: ${theme.colors.mainGrey};
  }
`;

const StyledLazyImage = styled(LazyImage)`
  width: 100%;
  height: 400px;
  border: 1px solid ${theme.colors.itemBorder};
  background: ${theme.colors.itemBorder};
  border-radius: 20px;
`;

const DateP = styled.p`
  margin-top: 0.25rem;
  color: #b1acbd;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
  text-transform: uppercase;

  ${theme.medias.gteLarge} {
    font-size: 14px;
    line-height: 32px;
  }
`;

const TitleH2 = styled.h2`
  font-weight: 600;
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 0;
  color: ${theme.colors.primaryBlue};
`;

const LearnMoreSpan = styled.span`
  display: block;
  font-weight: 600;
  letter-spacing: 0.025rem;
  margin-top: 1.5rem;
`;

const BlogPostCard = props => {
  const { slug, title, excerpt, date, thumbnail, ...rest } = props;

  return (
    <StyledLink href={`/blog/${slug}`} title={title} {...rest}>
      <StyledLazyImage fluid={thumbnail} title={title} alt={title} />
      <DateP>{formatDate(date)}</DateP>
      <TitleH2>{title}</TitleH2>
      <p>{excerpt}</p>
    </StyledLink>
  );
};

BlogPostCard.propTypes = {
  slug: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  date: PropTypes.string,
  categories: PropTypes.array,
  author: PropTypes.string,
  thumbnail: PropTypes.object,
  timeToRead: PropTypes.string,
};

BlogPostCard.defaultProps = {
  categories: [],
};

export default BlogPostCard;
