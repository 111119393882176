import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Container } from '@zd/components/grid';

import theme from '@zd/theme';

const Div = styled.div`
  border-bottom: 1px solid ${theme.colors.itemBorder};
  margin-bottom: 1rem;
  position: sticky;
  top: ${theme.variables.navbarMobileHeight};
  z-index: ${theme.layers.categoriesSticky};
  background: ${theme.colors.lightBrown};
  padding: 1rem 0;

  > h6 {
    color: ${theme.colors.primaryBlue};
    weight: ${theme.weights.bold};
  }

  ${theme.medias.gteLarge} {
    top: ${theme.variables.navbarDesktopHeight};
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;

  & > h6 {
    color: ${theme.colors.primaryBlue};
    weight: ${theme.weights.bold};
  }

  ${theme.medias.gteLarge} {
    display: flex;
    justify-content: space-between;
  }
`;

const SelectElement = styled.select`
  border: 0;
  background: transparent;
  color: ${theme.colors.mainGrey};
  margin-top: 0;

  ${theme.medias.gteLarge} {
    margin-top: 0;
  }
`;

const QuerySelect = props => {
  const { label, selected, options, defaultOption, onChange, ...rest } = props;

  return (
    <Div>
      <StyledContainer>
        <h6>{label}</h6>
        <SelectElement onChange={onChange} {...rest}>
          <option value={defaultOption.slug}>{defaultOption.title}</option>
          {options.map((option, i) => (
            <option key={i} value={option.slug} selected={option.slug === selected}>
              {option.title}
            </option>
          ))}
        </SelectElement>
      </StyledContainer>
    </Div>
  );
};

QuerySelect.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  defaultOption: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

QuerySelect.defaultProps = {
  options: [],
  defaultOption: {},
  // eslint-disable-next-line no-console
  onChange: () => undefined,
};

export default QuerySelect;
