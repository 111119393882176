import { useStaticQuery, graphql } from 'gatsby';

export default () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { fields: { sourceName: { eq: "blog-categories" } } }) {
        edges {
          node {
            frontmatter {
              title
              description
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);

  return data.allMarkdownRemark.edges.reduce(
    (acc, current) => ({
      ...acc,
      [current.node.fields.slug]: { ...current.node.frontmatter, slug: current.node.fields.slug },
    }),
    {}
  );
};
