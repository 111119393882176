import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { GoArrowRight as RightArrowIcon, GoArrowLeft as LeftArrowIcon } from 'react-icons/go';

import Link from '@zd/components/link';
import theme from '@zd/theme';

const WrapperDiv = styled.div`
  display: flex;
  justify-content: space-between;

  > * {
    width: calc(100% / 3);
    display: flex;
    align-items: center;
  }
`;

const PreviousLink = styled(Link)`
  text-align: left;
  justify-content: flex-start;

  &:hover {
    > span {
      color: ${theme.colors.primaryBlue};
    }
  }
`;

const PagesDiv = styled.div`
  text-align: center;
  justify-content: center;

  > * {
    margin: 0 0.25rem;
    list-style-type: none;
    margin: 0;

    &:first-of-type: {
      margin-left: 0;
    }

    &:last-of-type: {
      margin-right: 0;
    }

    &:not(:last-of-type) {
      &::after {
        margin: 0 0.5rem;
        content: '•';
      }
    }
  }
`;

const NextLink = styled(Link)`
  text-align: right;
  justify-content: flex-end;

  &:hover {
    > span {
      color: ${theme.colors.primaryBlue};
    }
  }
`;

const StyledLeftArrowIcon = styled(LeftArrowIcon)`
  color: ${theme.colors.primaryBlue};
  margin-right: 1rem;
`;

const StyledRightArrowIcon = styled(RightArrowIcon)`
  color: ${theme.colors.primaryBlue};
  margin-left: 1rem;
`;

const DividerSpan = styled.span`
  color: ${theme.colors.primaryBlue};
`;

const Pagination = props => {
  const {
    currentPage,
    numberOfPages,
    onPreviousPageClick,
    onNextPageClick,
    onPageClick,
    ...rest
  } = props;

  if (numberOfPages < 2) {
    return null;
  }

  const currentPageToRender = currentPage < 10 ? `0${currentPage}` : currentPage;
  const numberOfPagesToRender = numberOfPages < 10 ? `0${numberOfPages}` : numberOfPages;

  return (
    <WrapperDiv {...rest}>
      {currentPage > 1 ? (
        <PreviousLink onClick={onPreviousPageClick}>
          <StyledLeftArrowIcon /> <span>Mais recente</span>
        </PreviousLink>
      ) : (
        <div> </div>
      )}
      <PagesDiv>
        {/*[...Array(numberOfPages).keys()].map(page => (
          <li key={page}>
            <Link onClick={() => onPageClick(page + 1)}>{page + 1}</Link>
          </li>
        ))*/}
      </PagesDiv>
      {currentPage < numberOfPages ? (
        <NextLink onClick={onNextPageClick}>
          <span>Mais antigo</span> <StyledRightArrowIcon />
        </NextLink>
      ) : (
        <div> </div>
      )}
    </WrapperDiv>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number,
  numberOfPages: PropTypes.number,
  onPreviousPageClick: PropTypes.func.isRequired,
  onNextPageClick: PropTypes.func.isRequired,
};

Pagination.defaultProps = {
  currentPage: 1,
  numberOfPages: 5,
};

export default Pagination;
